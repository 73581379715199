import firebase from 'firebase/app';
import 'firebase/functions'
// import 'firebase/analytics'
import 'firebase/auth'
import "firebase/database";

var firebaseConfig = {
  apiKey: "AIzaSyDrc5_qhxVj10vqdCXZ65PEiqFkWegPBPo",
  authDomain: "avm-square.firebaseapp.com",
  projectId: "avm-square",
  storageBucket: "avm-square.appspot.com",
  messagingSenderId: "612913053158",
  appId: "1:612913053158:web:1c6541cdd5d84d55792a27",
  // measurementId: "G-1NJNR7XQ5K",
  databaseURL: "https://avm-square-default-rtdb.europe-west1.firebasedatabase.app/",
};

firebase.initializeApp(firebaseConfig);

let functions
if (location.hostname === "localhost") {
  firebase.functions().useFunctionsEmulator('http://localhost:5001')
  functions = firebase.functions();
} else {
  functions = firebase.app().functions('europe-west1');
}


// export const analytics = firebase.analytics();
export const firebaseFunctions = functions;
export const realtimedatabase = firebase.database()
export const auth = firebase.auth()